import React from "react";
import TextField from "@material-ui/core/TextField"
import * as Styled from "./style"

const Searchbar = ({english, SearchKeyword, setSearchKeyword = () => {}}) => {
  return (
    <Styled.Searchbar id="search">
      <TextField
        id="outlined-search"
        label={english ? "Search" : "Sök"}
        type="search"
        margin="normal"
        variant="outlined"
        fullWidth={true}
        onChange={e => setSearchKeyword(e.target.value)}
        value={SearchKeyword}
      />
    </Styled.Searchbar>
  );
};

export default Searchbar;
