
import styled from "styled-components";


export const Searchbar = styled.div`
  margin-top: 3%;
  width: 1200px;
  display: flex;
  margin: auto;
  flex-direction: column;

  /* width on search bar to match the flex-grid */
  @media(max-width: 1250px){
    max-width: 900px;
  }
  @media(max-width: 920px){
    max-width: 600px;
  }
  @media(max-width: 600px){
    max-width: 90%;
  }
`;