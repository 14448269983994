import { graphql, useStaticQuery } from "gatsby";

export const HotelQuery = () => {
  let query = useStaticQuery(
    graphql`
    query {
      allContentfulHotellSida {
        edges{
          node{
            node_locale
            heroRubrik
            seoTitel
            seoDescription
            heroUnderRubrik {
              heroUnderRubrik
            }
            heroBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
              file {
                contentType
                url
              }
            }
            introText {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
    }
    `
  );
  return query.allContentfulHotellSida;
};
