import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Link } from "gatsby"
import getLang from '../components/scripts/getLang'
import * as Styled from "../css/CardFlexStyle"
import { EnheterQuery } from './scripts/graphQlQuerys/enheterQuery'

export const HotelLokalSearch = ({ SearchKeyword, english }) => {
  if (SearchKeyword === undefined) {
    SearchKeyword = ""
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi")
  let hotell = getLang(english, EnheterQuery())
    .filter(edges => {
      return edges.node.sida.includes("Hotell")
    })
    .filter(hotel => {
      let newString = hotel.node.skord.toString()
      return newString.match(regex)
    })
    .map((item, index) => {
      let orderOne = "";
      if(item.node.highlight){
        orderOne = "orderOne"
      }
      return (
        <Link
          key={index}
          className={"textDecorationNone extraLarge orderOne " + orderOne}
          to={`/hotel/${item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={item.node.huvudBild.fluid}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      )
    })

  return <Styled.CenterDiv><Styled.FlexContainer page="hotel">{hotell}</Styled.FlexContainer></Styled.CenterDiv>
}



