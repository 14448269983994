import React, { useState } from "react"
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import { HotelLokalSearch } from "../../components/hotelSearch";
import App from "../app";
import getLang from "../../components/scripts/getLang";
import Searchbar from "../../components/searchbar";
import Hero from '../../components/hero'
import * as Global from "../../css/globalStyle";

import { HotelQuery } from "../../components/scripts/graphQlQuerys/hotelQuery";

const Hotel = () => {
  const [SearchKeyword, setSearchKeyword] = useState('')
  let [english] = useGlobal("English");
  let HeroData = getLang(english, HotelQuery());
  let { seoTitel, seoDescription, introText } = HeroData[0].node;
  
  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={introText} heroTema={null} data={HeroData} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} />
      <HotelLokalSearch SearchKeyword={SearchKeyword}/>
    </App>
  );
};
export default Hotel;
